export default class YouTubeVideo {

    constructor(ID, imageUrl, altDescription, title, location, description, youTubeLink) {
      this.ID = ID;
      this.imageUrl = imageUrl;
      this.altDescription = altDescription;
      this.title = title;
      this.location = location;
      this.description = description;
      this.youTubeLink = youTubeLink;
    }
    
  }