import { YouTubeLatest } from "Components";

const Latest = () => {
  return (
    <>
        <YouTubeLatest link='https://www.youtube.com/embed/i6CfPYxzAFM?si=QgQfUI5fVQCtJXEK' />
    </>
  );
};

export default Latest;